import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import {AdminComponent} from './admin/admin.component';
import {FormsComponent} from './forms/forms.component';
import {LocuinteComponent} from './locuinte/locuinte.component';
import {ApartamenteComponent} from './apartamente/apartamente.component';
import {ContactComponent} from './contact/contact.component';
import {DezvoltatorComponent} from './dezvoltator/dezvoltator.component';
import {LifestyleComponent} from './lifestyle/lifestyle.component';
import {LocatieComponent} from './locatie/locatie.component';
import {MediapageComponent} from './mediapage/mediapage.component';
import {TermsComponent} from './terms/terms.component';
import {QrredirectComponent} from './qrredirect/qrredirect.component';
import {TestHomeComponent} from './test-home/test-home.component';
import {EnglishHComponent} from './home/english-h/english-h.component';
import {EnglishLComponent} from './lifestyle/english-l/english-l.component';
import {EnglishLoComponent} from './locatie/english-lo/english-lo.component';
import {EnglishMComponent} from './mediapage/english-m/english-m.component';
import {EnglishDComponent} from './dezvoltator/english-d/english-d.component';
import {EnglishCComponent} from './contact/english-c/english-c.component';
import {EnglishRezComponent} from './locuinte/english-rez/english-rez.component';
import {EnglishAComponent} from './apartamente/english-a/english-a.component';
import {EnglishTComponent} from './terms/english-t/english-t.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { EnglishLpComponent } from './landing-page/english-lp/english-lp.component';
import { LpChecklistComponent } from './lp-checklist/lp-checklist.component';
import { ChecklistFinalComponent } from './lp-checklist/checklist-final/checklist-final.component';
import { LpChecklistMainComponent } from './lp-checklist-main/lp-checklist-main.component'
import {BrosuraComponent} from './brosura/brosura.component';
import {OpenComponent} from './brosura/open/open.component';
import { BlogComponent } from './blog/blog.component';
import { BlogArticleComponent } from './blog/blog-article/blog-article.component';
import { ThanksComponent } from './lp-checklist-main/thanks/thanks.component';
import { RuffleComponent } from './forms/ruffle/ruffle.component';
import { RulesComponent} from './rules/rules.component';
import { Ruffle2Component } from './forms/ruffle2/ruffle2.component';
import { AddBComponent } from './forms/add-b/add-b.component';
import { AddComponent } from './forms/add-b/add/add.component';
import { AirbnbComponent } from './airbnb/airbnb.component';
import { OfertaComponent } from './oferta/oferta.component';
import { InvestComponent } from './invest/invest.component';
import { EmailComponent } from './email/email.component';
import { EmailEventComponent } from './email-event/email-event.component';
import { SupportFormComponent } from './support-form/support-form.component';
import { BusinessThanksComponent } from './support-form/business-thanks/business-thanks.component';
import { GalleryComponent } from './gallery/gallery.component';
import {GalleryEnComponent} from './gallery/gallery-en/gallery-en.component';
import { FloorPlanComponent } from './floor-plan/floor-plan.component';
import { EmailEnComponent } from './email-en/email-en.component';
import { MapComponent } from './map/map.component';
import { RentComponent } from './rent/rent.component';

const routes: Routes = [
  {path:'',component:HomeComponent},
  {path:'1admin1',component:AdminComponent},
  {path:'secretforms1111',component:FormsComponent},
  {path:'locuinte',component:LocuinteComponent},
  {path:'apartamente',component:ApartamenteComponent},
  {path:'contact',component:ContactComponent},
  {path:'dezvoltator',component:DezvoltatorComponent},
  {path:'lifestyle',component:LifestyleComponent},
  {path:'locatie',component:LocatieComponent},
  {path:'mediapage',component:MediapageComponent},
  {path:'termeni-si-conditii',component:TermsComponent},
  {path:'locuintamea',component:QrredirectComponent},
  {path:'19.169.12.221',component:TestHomeComponent},
  {path:'en',component:EnglishHComponent},
  {path:'lifestyle-en',component:EnglishLComponent},
  {path:'locatie-en',component:EnglishLoComponent},
  {path:'mediapage-en',component:EnglishMComponent},
  {path:'dezvoltator-en',component:EnglishDComponent},
  {path:'contact-en',component:EnglishCComponent},
  {path:'locuinte-en',component:EnglishRezComponent},
  {path:'apartamente-en',component:EnglishAComponent},
  {path:'termeni-si-conditii-en',component:EnglishTComponent},
  {path:'festivallake',component:LandingPageComponent},
  {path:'thelakepark-en',component:EnglishLpComponent},
  { path: 'thelakepark', redirectTo: 'festivallake', pathMatch: 'full' },
  { path: 'alege-casa-perfecta', component:LpChecklistComponent, pathMatch: 'full'},
  {path:'descarcaformular',component:ChecklistFinalComponent},
  {path:'brosura/form',component:OpenComponent},
  {path:'b/brosura',component:BrosuraComponent},
  { path: 'alegecasaperfecta', redirectTo: 'alege-casa-perfecta', pathMatch: 'full' },
  { path: 'blog', component: BlogComponent },
  { path: 'blog-article/:title', component: BlogArticleComponent }, // Using first word of the title
  {path:'castiga',component:LpChecklistMainComponent},
  {path:'redirect',component:ThanksComponent},
  {path:'raffleenteries',component:RuffleComponent},
  {path:'reguli-festival-lake',component:RulesComponent},
  {path:'raffleenteries-today',component:Ruffle2Component},
  {path:'add-b',component:AddBComponent},
  {path:'add-b/add',component:AddComponent},
  {path:'optiuni-de-investitie/:lang',component:AirbnbComponent},
  {path:'oferta',component:OfertaComponent},
  {path:'invest',component:InvestComponent},
  {path:'email',component:EmailComponent},
  {path:'event-email',component:EmailEventComponent},
  {path:'event-form',component:SupportFormComponent},
  {path:'multumim',component:BusinessThanksComponent},
  { path: 'programeaza-o-vizionare', redirectTo: '/?openModal=vizionare', pathMatch: 'full' },
  { path: 'investitie', redirectTo: '/?openModal=new', pathMatch: 'full' },
  {path:'galerie',component:GalleryComponent},
  {path:'galerie-en',component:GalleryEnComponent},
  {path:'floorplan',component:FloorPlanComponent},
  {path:'email-en',component:EmailEnComponent},
  {path:'festival-map',component:MapComponent},
  {path:'airbnb',component:RentComponent},
  

  { path: '**', redirectTo: '/', pathMatch: 'full' }

];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->


<head>
    <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
    
    <!-- Start cookieyes banner --> 
    <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/da5a7224cc2fca63b25cfafc/script.js"></script> 
    <!-- End cookieyes banner -->
    </head>

<body>


    <header class="headerGrid">
        <video loop onloadedmetadata="this.muted=true" autoplay oncanplay="this.play()" class="bg-vid">
            <source
                src="https://the-lake-live.s3.eu-central-1.amazonaws.com/2022.11.30_TRACTORULUI+-+ANIMATIE+FAZA+I+-+SCENA+1+%26+2_.mp4">
        </video>
        <div class="headerGrid-wrapper">

            <div class="header-titles">
                <img src="../../assets/media/logos/the_lake_logo_white.png" alt="The Lake home - Living Xperience logo"
                    (click)="seehome()">

                <div class="all-nav-elements">
                    <div class="contact-nav">
                        <div class="header-phone"><span id="header-icon-phone"
                                class="material-symbols-outlined">phone_iphone <a href="tel:+40721229999">+4 0721 229
                                    999</a> </span></div>
                        <span class="vertical-line"></span>
                        <div class="header-mail"><span id="header-icon-mail"
                                class="material-symbols-outlined">chat_bubble <a
                                    href="mailto:sales@thelake.ro">sales@thelake.ro</a></span></div>
                        <!-- Button trigger modal vizionare-->
                        <button class="header-button" type="button" data-toggle="modal"
                            data-target="#brosuraModal">vezi&nbsp;broșura</button>



                        <!-- Modal Vizionare-->
                        <div class="modal fade" id="vizionareModal" tabindex="-1" role="dialog"
                            aria-labelledby="vizionareModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title" id="vizionareModalLabel">Programează o vizionare</h5>
                                    </div>
                                    <div class="modal-body">
                                        <div class="p">
                                            <p>Trimite-ne datele tale de contact pentru a putea fi preluate de către
                                                echipa noastră.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Name" placeholder="Nume" required>
                                            <input type="email" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Email" id="exampleFormControlInput1" placeholder="Email"
                                                required>
                                            <input type="tel" class="modal-form" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Phone" placeholder="Telefon" required>
                                            <textarea id="modal-mesaj" class="modal-form"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Message"
                                                placeholder="Scrie-ne un mesaj" rows="3"></textarea>

                                            <div class="privacylink1"><input (click)="checked()" id="checkbox1"
                                                    type="checkbox" required><label class="form-control1">Sunt de acord
                                                    cu <a id="ppLink" (click)="seetermeni()"
                                                        data-dismiss="modal">Consimțământul de prelucrare a
                                                        datelor.</a></label></div>
                                            <h6 *ngIf="acestCamp"> Acest câmp este obligatoriu</h6>

                                            <button class="deactive modal-button" type="button" (click)="sendFormOne()"
                                                data-dismiss="modal">TRIMITE MESAJUL</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Modal Brosura-->
                        <div class="modal fade" id="brosuraModal" tabindex="-1" role="dialog"
                            aria-labelledby="brosuraModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title" id="brosuraModalLabel">Vezi broșura</h5>
                                    </div>
                                    <div class="modal-body-brosura">
                                        <div class="p">
                                            <p>Completează formularul de mai jos pentru a descărca broșura The Lake
                                                Home.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" placeholder="Nume"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                                            <input type="email" class="modal-form" id="exampleFormControlInput1"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                                                placeholder="Email" required>
                                            <input type="tel" class="modal-form" placeholder="Telefon"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                                            <textarea id="modal-mesaj" class="modal-form"
                                                placeholder="Scrie-ne un mesaj" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Message" rows="3"></textarea>

                                            <div class="privacylink2"><input (click)="checked2()" id="checkbox2"
                                                    type="checkbox" required><label class="form-control2">Sunt de acord
                                                    cu <a id="ppLink2" data-dismiss="modal"
                                                        (click)="seetermeni()">Consimțământul de prelucrare a
                                                        datelor.</a></label></div>
                                            <h6 *ngIf="acestCamp1">Acest câmp este obligatoriu</h6>
                                            <button class="deactive modal-button-brosura" type="button"
                                                (click)="sendFormOne2()" data-dismiss="modal">TRIMITE MESAJUL</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- XMAS promo Modal-->
                        <div class="modal fade" id="XmasModal" tabindex="-1" role="dialog"
                            aria-labelledby="XmasModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content XmasContent">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title XmasTitle" id="OfertaModalLabel">HO, HO,
                                            <span>HOME</span></h5>
                                    </div>
                                    <div class="modal-body-oferta">
                                        <div class="p">

                                            <p> <span class="xmasSubTitle">Serbează <strong>Crăciunul</strong> acasă și
                                                    fă loc
                                                    sub brad pentru <strong>un cadou în plus.</strong> </span><br><br>

                                                Achiziționează unul din ultimele apartamente disponibile
                                                în blocul C sau achită <strong>un avans de 50% </strong>pentru un
                                                apartament în blocul B1 și primești cadou de casă nouă
                                                un <strong>robot de bucătărie Thermomix TM6.</strong> <br><br>

                                                În plus, îți oferim un<strong> avantaj client</strong> cuprins între
                                                <strong>2,500 și
                                                    38,000 euro </strong>în baza avansului din prețul de listă pentru
                                                noua ta locuință în blocurile B1 și B2.<br><br>

                                                Lasă-ne datele tale și programează o vizionare.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" placeholder="Nume"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                                            <input type="email" class="modal-form" id="exampleFormControlInput1"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                                                placeholder="Email" required>
                                            <input type="tel" class="modal-form" placeholder="Telefon"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                                            <textarea id="modal-mesaj" class="modal-form"
                                                placeholder="Scrie-ne un mesaj" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Message" rows="3"></textarea>

                                                <div class="privacylink2"><input (click)="checked4()" id="checkbox2"
                                                    type="checkbox" required><label class="form-control2">Sunt de acord
                                                    cu <a id="ppLink2" data-dismiss="modal"
                                                        (click)="seetermeni()">Consimțământul de prelucrare a
                                                        datelor.</a></label></div>
                                            <h6 *ngIf="acestCamp1">Acest câmp este obligatoriu</h6>
                                            <button class="deactive modal-button-oferta" type="button"
                                                (click)="sendFormOferta()" data-dismiss="modal">TRIMITE MESAJUL</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Button trigger modal 
                        <button type="button" id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#OFERTA"> </button>
                    Modal Oferta    
                        <div class="modal fade" id="OFERTA" tabindex="-1" role="dialog" aria-labelledby="OFERTALabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                            <div class="modal-content o">
                                <div class="modal-header">

                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div>
                                <div class="modal-body-oferta" (click)="openOferta()">
                                 <img id="ofertaImg" src="../../assets/media/blackfriday.jpg" alt="">
                                </div>
                                <div class="modal-footer">
                                </div>
                            </div>
                            </div>
                        </div>-->



                    <!-----------NAV BAR----------->
                    <div class="logo-navbar">

                        <div class="mob-navbar">
                            <div class="distance-top-navbar">
                                <a class="blue-border" href="" routerLink="lifestyle">LIFESTYLE</a>
                                <a class="blue-border" href="" routerLink="locuinte">ALEGE-ȚI&nbsp;LOCUINȚA</a>
                                <a class="blue-border" href="" routerLink="locatie">LOCAȚIE</a>
                                <a class="blue-border" (click)="galerie()">GALERIE</a>
                                <a class="blue-border" href="" routerLink="mediapage">MEDIA</a>
                                <a class="blue-border" href="" routerLink="dezvoltator">DEZVOLTATOR</a>
                                <a class="blue-border" href="" routerLink="dezvoltator">CHECKLIST</a>
                                <a href="" href="" routerLink="contact">CONTACT</a>
                            </div>

                        </div>
                        <div class="burger" (click)="navSlider()">
                            <div class="line1"></div>
                            <div class="line2"></div>
                            <div class="line3"></div>
                        </div>

                        <div class="navbar">
                            <a routerLink="/lifestyle">LIFESTYLE</a>
                            <a (click)="footerlinks1()">ALEGE-ȚI&nbsp;LOCUINȚA</a>
                            <a routerLink="/locatie">LOCAȚIE</a>
                            <a (click)="galerie()">GALERIE</a>
                            <a routerLink="/mediapage">MEDIA</a>
                            <a routerLink="/dezvoltator">DEZVOLTATOR</a>
                            <a routerLink="/dezvoltator">CHECKLIST</a>
                            <a routerLink="/contact">CONTACT</a>
                        </div>
                    </div>
                </div>
                <div class="page-title">
                    <img src="../../assets/media/header-title.svg" alt="">
                </div>
            </div>

            <div class="header-database">
                <div class="dataBase-wrapper">
                    <h5>Vezi planuri</h5>
                    <div class="db-camere">
                        <div class="camere-cicle"><span id="db-camere-arrow"
                                class="material-symbols-outlined">chevron_right</span>
                        </div>
                        <div class="camere-names" id="moreCamere">
                            <h6 class="camere-arrow">ETAJ</h6>
                            <ul class="nrCamere">
                                <li (click)="makeEtajParter()">0</li>
                                <li (click)="makeEtaj1()">1</li>
                                <li (click)="makeEtaj2()">2</li>
                                <li (click)="makeEtaj3()">3</li>
                                <li (click)="makeEtaj4()">4</li>
                            </ul>
                        </div>
                    </div>


                    <!-- 
                      <div class="db-vila">
                          <div class="vila-cicle"><span id="db-vila-arrow" class="material-symbols-outlined">chevron_right</span></div>
                              <div class="vila-names" id="moreVila">
                                  <h6 class="vila-arrow">Vila </h6>
                              </div>    
                      </div>
                    -->
                    <div class="db-camere">
                        <div class="camere-cicle"><span id="db-camere-arrow"
                                class="material-symbols-outlined">chevron_right</span>
                        </div>
                        <div class="camere-names" id="moreCamere">
                            <h6 class="camere-arrow">nr. camere </h6>
                            <ul class="nrCamere1">
                                <li class="deactive" (click)="makerooms1()">1</li>
                                <li class="deactive" (click)="makerooms2()">2</li>
                                <li class="deactive" (click)="makerooms3()">3</li>
                                <li class="deactive" (click)="makerooms4()">4</li>
                                <li class="deactive" (click)="makerooms5()">5</li>
                            </ul>
                        </div>
                    </div>
                    <div class="db-button" (click)="seemoreapartments()"><button class="deactive">VEZI LISTA</button>
                    </div>
                </div>
            </div>

            <!-- call to action buttons -->
            <div class="cta-btns">
                <div class="cta-visionare" type="button" data-toggle="modal" data-target="#vizionareModal">
                    <span id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span>
                    <p>programează o vizionare</p>
                </div>
                <div class="cta-brosura" type="button" data-toggle="modal" data-target="#brosuraModal">
                    <img src="../../assets/media/icons/brosuraIcon.svg" alt="download brosura icon">
                    <p>Vezi broșura</p>
                </div>
                
            </div>

            <div class="sticky-btn" type="button" data-toggle="modal" data-target="#vizionareModal"><span id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span>
                <h5>programează <br> o vizionare</h5>
            </div>

            <!--xmasPromo
            <div class="XMAS-sticky-btn" type="button" data-toggle="modal" data-target="#XmasModal"><img
                    src="../../assets/media/icons/favpng_vector-graphics-gift-clip-art-box.png" alt="">
            </div>-->
        </div>
    </header>

    <section class="mob-tag-section">
        <div class="mob-tag-slider">
            <ul #slider class="mobBxslider">
                <li class="tagElements">
                    <div class="mob-tag">
                        <img class="blueCircle" src="../../assets/media/icons/tag-comunitate.svg" alt="">
                        <div id="mobtag1content">
                            <h3>Comunitate</h3>
                            <p>The Lake Home se află poziționat chiar lângă Lacul lui Binder, zonă special selectată
                                pentru a oferi acces facil la <span class="tag-moreDots"> ...</span> diverse activități
                                sportive, zone de agrement, spații culturale și comerciale. <br><br> În apropiere va fi
                                finalizat noul Spital Regional, iar Centrul Vechi se află la o distanță de aproximativ
                                10 minute cu mașina. Complexul este definit prin locatarii săi, persoane aflate în
                                căutarea unui loc exclusivist, dar totodată liniștit, ușor accesibil și foarte bine
                                poziționat în raport cu “centrii energetici” ai orașului.
                            </p>
                            <div class="tag-buttons" id="mobtagBtn" (click)="setAllAccordians(0)">
                                <span id="mob-tag-icon1" class="material-symbols-outlined">arrow_circle_down</span>
                                <label class="tag-open"> Citește mai mult </label>
                                <label class="tag-close"> Închide </label>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="tagElements">
                    <div class="mob-tag">
                        <img class="blueCircle" src="../../assets/media/icons/tag-casa.svg" alt="">
                        <div id="mobtag2content">
                            <h3>Aici e casa ta</h3>
                            <p>Sibiu este deja un brand din punct de vedere cultural și economic, un oraș care trezește
                                emoții, care te face <span class="tag-moreDots"> ... <br></span> să te simți localnic și
                                turist, în același timp. <br><br> Indiferent de postura în care alegi să fii, în
                                ansamblul rezidențial The Lake Home, simți că poți fi tu. Tu cel mereu tânăr, înconjurat
                                de prieteni și familie, în apropierea celor mai interesante puncte de atracție. Ai la
                                dispoziție trei restaurante, cafenea, sală de sport și spa cu bazin de înot.
                            </p>
                            <div class="tag-buttons" id="mobtagBtn2" (click)="setAllAccordians(1)">
                                <span id="mob-tag-icon2" class="material-symbols-outlined">arrow_circle_down</span>
                                <label class="tag-open"> Citește mai mult </label>
                                <label class="tag-close"> Închide </label>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="tagElements">
                    <div class="mob-tag">
                        <img class="blueCircle" src="../../assets/media/icons/tag-gift.svg" alt="">
                        <div id="mobtag3content">
                            <h3>Un loc dorit de toți</h3>
                            <p>Confortul nu trebuie să fie o extra opțiune. Ansamblul rezidențial The Lake Home este
                                locul unde confortul<span class="tag-moreDots"> ... <br></span> reprezintă baza
                                contractului dintre noi și dumneavoastră. Ne asigurăm că veți fi atât de mulțumiți,
                                încât va afla toată lumea despre acest loc unic. <br><br> Vă tratăm cu un respect
                                deosebit, vă punem la dispoziție cei mai buni specialiști, care să ofere servicii de
                                securitate, salubritate și întreținere a tuturor spațiilor verzi inclusiv jardiniere și,
                                totodată, vă putem facilita diverse alte beneficii, conform exigențelor dumneavoastră.
                            </p>
                            <div class="tag-buttons" id="mobtagBtn3" (click)="setAllAccordians(2)">
                                <span id="mob-tag-icon3" class="material-symbols-outlined">arrow_circle_down</span>
                                <label class="tag-open"> Citește mai mult </label>
                                <label class="tag-close"> Închide </label>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="tagElements">
                    <div class="mob-tag">
                        <img class="blueCircle" src="../../assets/media/icons/tag-dorit.svg" alt="">
                        <div id="mobtag4content">
                            <h3>Cartierul ideal</h3>
                            <p>Locuitorii noului complex vor avea, în scurt timp, la dispoziție, pe lângă apartamentele
                                proprii, minuțios <span class="tag-moreDots"> ...<br></span>elaborate și riguros
                                construite, trei restaurante, cafenea, sală de sport, piscină, spa, dar si un
                                aparthotel. <br><br>The Lake Home este un vis îndrăzneț, care a ajuns realitate datorită
                                unei mari pasiuni; pasiune pentru estetic, durabilitate, pentru arhitectură și design de
                                top. Așa cum ne place să spunem, oferim la pachet siguranță, încredere și vecini
                                deosebiți.
                            </p>
                            <div class="tag-buttons" id="mobtagBtn4" (click)="setAllAccordians(3)">
                                <span id="mob-tag-icon4" class="material-symbols-outlined">arrow_circle_down</span>
                                <label class="tag-open"> Citește mai mult </label>
                                <label class="tag-close"> Închide </label>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="tagElements">
                    <div class="mob-tag">
                        <img class="blueCircle" src="../../assets/media/icons/tag-verde.svg" alt="">
                        <div id="mobtag5content">
                            <h3>Un cartier verde</h3>
                            <p>The Lake Home este singurul cartier de mare amploare din orașul Sibiu situat lângă un
                                lac, cu zonă de agrement și <span class="tag-moreDots"> ... <br></span> sporturi
                                nautice, iar în proiect sunt incluse aprox 2 ha parc cu amenajări spații de joacă și
                                restaurant. Toate blocurile au acces la drum asfaltat, eliminând orice potențial
                                disconfort.
                            </p>
                            <div class="tag-buttons" id="mobtagBtn5" (click)="setAllAccordians(4)">
                                <span id="mob-tag-icon5" class="material-symbols-outlined">arrow_circle_down</span>
                                <label class="tag-open"> Citește mai mult </label>
                                <label class="tag-close"> Închide </label>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="tagElements">
                    <div class="mob-tag">
                        <img class="blueCircle" src="../../assets/media/icons/tag-casaunica.svg" alt="">
                        <div id="mobtag6content">
                            <h3>O casă unică</h3>
                            <p>Acest proiect Premium trece dincolo de așteptări, pentru a bucura clientul, uimește prin
                                cadrul inedit de abordare<span class="tag-moreDots"> ...<br></span> în designul
                                arhitectural și duce inserțiile sale de industrial, asumate în modernism, la un cu totul
                                alt nivel.
                            </p>
                            <div class="tag-buttons" id="mobtagBtn6" (click)="setAllAccordians(5)">
                                <span id="mob-tag-icon6" class="material-symbols-outlined">arrow_circle_down</span>
                                <label class="tag-open"> Citește mai mult </label>
                                <label class="tag-close"> Închide </label>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="tagElements">
                    <div class="mob-tag">
                        <img class="blueCircle" src="../../assets/media/pattern/BlueBox_B.svg" alt="">
                        <div id="mobtag7content">
                            <h3>Cartier cu maniere</h3>
                            <p>Am denumit această comunitate „Cartierul Bunelor Maniere” și chiar avem un regulament de
                                ordine<span class="tag-moreDots"> ...<br></span> interioară, bazat pe bune maniere,
                                respect și încredere. În The Lake Home, găsești vecini de calitate și prieteni buni.</p>
                            <div class="tag-buttons" id="mobtagBtn7" (click)="setAllAccordians(6)">
                                <span id="mob-tag-icon7" class="material-symbols-outlined">arrow_circle_down</span>
                                <label class="tag-open"> Citește mai mult </label>
                                <label class="tag-close"> Închide </label>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="tagElements">
                    <div class="mob-tag">
                        <img class="blueCircle" src="../../assets/media/icons/tag-seguranta.svg" alt="">
                        <div id="mobtag8content">
                            <h3>Siguranță 100%</h3>
                            <p>Acest cartier este deosebit și prin faptul că va fi administrat de către dezvoltator pe o
                                durată de 25 de ani.<span class="tag-moreDots"> ...<br></span> Practic, cumpărătorul
                                primește un stil de viață bazat pe confort și încredere, mod de a trăi și experiență în
                                cartier.
                            </p>
                            <div class="tag-buttons" id="mobtagBtn8" (click)="setAllAccordians(7)">
                                <span id="mob-tag-icon8" class="material-symbols-outlined">arrow_circle_down</span>
                                <label class="tag-open"> Citește mai mult </label>
                                <label class="tag-close"> Închide </label>
                            </div>
                        </div>
                    </div>
                </li>

            </ul>

            <div class="mob-tag-slider-controls" style="display: flex;">
                <span id="mob-tag-slide-prev" (click)="onLeftScroll()"
                    class="material-symbols-outlined">arrow_circle_left</span>
                <span id="mob-tag-slide-next" (click)="onRightScroll()"
                    class="material-symbols-outlined">arrow_circle_right</span>
            </div>

        </div>
    </section>

    <section class="tag-section">
        <div class="tag-section-wrapper" id="tagSectionWrapper">
            <div class="tag-wrapper">

                <div class="tag-1">
                    <img class="blueCircle" src="../../assets/media/icons/tag-comunitate.svg" alt="">
                    <div id="tag1content">
                        <h3>Comunitate</h3>
                        <p>The Lake Home se află poziționat chiar lângă Lacul lui Binder, zonă special selectată pentru
                            a oferi acces facil la <span class="tag-moreDots"> ...</span> diverse activități sportive,
                            zone de agrement, spații culturale și comerciale. <br><br> În apropiere va fi finalizat noul
                            Spital Regional, iar Centrul Vechi se află la o distanță de aproximativ 10 minute cu mașina.
                            Complexul este definit prin locatarii săi, persoane aflate în căutarea unui loc exclusivist,
                            dar totodată liniștit, ușor accesibil și foarte bine poziționat în raport cu “centrii
                            energetici” ai orașului.
                        </p>
                        <div class="tag-buttons" id="tagBtn" (click)="setAllAccordians(8)">
                            <span id="tag-icon1" class="material-symbols-outlined">arrow_circle_down</span>
                            <label class="tag-open"> Citește mai mult </label>
                            <label class="tag-close"> Închide </label>
                        </div>
                    </div>
                </div>

                <div class="tag-2">
                    <img class="blueCircle" src="../../assets/media/icons/tag-casa.svg" alt="">
                    <div id="tag2content">
                        <h3>Aici e casa ta</h3>
                        <p>Sibiu este deja un brand din punct de vedere cultural și economic, un oraș care trezește
                            emoții, care te face <span class="tag-moreDots"> ... <br></span> să te simți localnic și
                            turist, în același timp. <br><br> Indiferent de postura în care alegi să fii, în ansamblul
                            rezidențial The Lake Home, simți că poți fi tu. Tu cel mereu tânăr, înconjurat de prieteni
                            și familie, în apropierea celor mai interesante puncte de atracție. Ai la dispoziție trei
                            restaurante, cafenea, sală de sport și spa cu bazin de înot.
                        </p>
                        <div class="tag-buttons" id="tagBtn2" (click)="setAllAccordians(9)">
                            <span id="tag-icon2" class="material-symbols-outlined">arrow_circle_down</span>
                            <label class="tag-open"> Citește mai mult </label>
                            <label class="tag-close"> Închide </label>
                        </div>
                    </div>
                </div>

                <div class="tag-3">
                    <img class="blueCircle" src="../../assets/media/icons/tag-gift.svg" alt="">
                    <div id="tag3content">
                        <h3>Un loc dorit de toți</h3>
                        <p>Confortul nu trebuie să fie o extra opțiune. Ansamblul rezidențial The Lake Home este locul
                            unde confortul<span class="tag-moreDots"> ... <br></span> reprezintă baza contractului
                            dintre noi și dumneavoastră. Ne asigurăm că veți fi atât de mulțumiți, încât va afla toată
                            lumea despre acest loc unic. <br><br> Vă tratăm cu un respect deosebit, vă punem la
                            dispoziție cei mai buni specialiști, care să ofere servicii de securitate, salubritate și
                            întreținere a tuturor spațiilor verzi inclusiv jardiniere și, totodată, vă putem facilita
                            diverse alte beneficii, conform exigențelor dumneavoastră.
                        </p>
                        <div class="tag-buttons" id="tagBtn3" (click)="setAllAccordians(10)">
                            <span id="tag-icon3" class="material-symbols-outlined">arrow_circle_down</span>
                            <label class="tag-open"> Citește mai mult </label>
                            <label class="tag-close"> Închide </label>
                        </div>
                    </div>
                </div>

                <div class="tag-4">
                    <img class="blueCircle" src="../../assets/media/icons/tag-dorit.svg" alt="">
                    <div id="tag4content">
                        <h3>Cartierul ideal</h3>
                        <p>Locuitorii noului complex vor avea, în scurt timp, la dispoziție, pe lângă apartamentele
                            proprii, minuțios <span class="tag-moreDots"> ...<br></span>elaborate și riguros construite,
                            trei restaurante, cafenea, sală de sport, piscină, spa, dar si un aparthotel. <br><br>The
                            Lake Home este un vis îndrăzneț, care a ajuns realitate datorită unei mari pasiuni; pasiune
                            pentru estetic, durabilitate, pentru arhitectură și design de top. Așa cum ne place să
                            spunem, oferim la pachet siguranță, încredere și vecini deosebiți.
                        </p>
                        <div class="tag-buttons" id="tagBtn4" (click)="setAllAccordians(11)">
                            <span id="tag-icon4" class="material-symbols-outlined">arrow_circle_down</span>
                            <label class="tag-open"> Citește mai mult </label>
                            <label class="tag-close"> Închide </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tag-wrapper-row">
                <div class="tag-5">
                    <img class="blueCircle" src="../../assets/media/icons/tag-verde.svg" alt="">
                    <div id="tag5content">
                        <h3>Un cartier verde</h3>
                        <p>The Lake Home este singurul cartier de mare amploare din orașul Sibiu situat lângă un lac, cu
                            zonă de agrement și <span class="tag-moreDots"> ... <br></span> sporturi nautice, iar în
                            proiect sunt incluse aprox 2 ha parc cu amenajări spații de joacă și restaurant. Toate
                            blocurile au acces la drum asfaltat, eliminând orice potențial disconfort.
                        </p>
                        <div class="tag-buttons" id="tagBtn5" (click)="setAllAccordians(12)">
                            <span id="tag-icon5" class="material-symbols-outlined">arrow_circle_down</span>
                            <label class="tag-open"> Citește mai mult </label>
                            <label class="tag-close"> Închide </label>
                        </div>
                    </div>
                </div>

                <div class="tag-6">
                    <img class="blueCircle" src="../../assets/media/icons/tag-casaunica.svg" alt="">
                    <div id="tag6content">
                        <h3>O casă unică</h3>
                        <p>Acest proiect Premium trece dincolo de așteptări, pentru a bucura clientul, uimește prin
                            cadrul inedit de abordare<span class="tag-moreDots"> ...<br></span> în designul arhitectural
                            și duce inserțiile sale de industrial, asumate în modernism, la un cu totul alt nivel.
                        </p>
                        <div class="tag-buttons" id="tagBtn6" (click)="setAllAccordians(13)">
                            <span id="tag-icon6" class="material-symbols-outlined">arrow_circle_down</span>
                            <label class="tag-open"> Citește mai mult </label>
                            <label class="tag-close"> Închide </label>
                        </div>
                    </div>
                </div>

                <div class="tag-7">
                    <img class="blueCircle" src="../../assets/media/pattern/BlueBox_B.svg" alt="">
                    <div id="tag7content">
                        <h3>Cartier cu maniere</h3>
                        <p>Am denumit această comunitate „Cartierul Bunelor Maniere” și chiar avem un regulament de
                            ordine<span class="tag-moreDots"> ...<br></span> interioară, bazat pe bune maniere, respect
                            și încredere. În The Lake Home, găsești vecini de calitate și prieteni buni.</p>
                        <div class="tag-buttons" id="tagBtn7" (click)="setAllAccordians(14)">
                            <span id="tag-icon7" class="material-symbols-outlined">arrow_circle_down</span>
                            <label class="tag-open"> Citește mai mult </label>
                            <label class="tag-close"> Închide </label>
                        </div>
                    </div>
                </div>

                <div class="tag-8">
                    <img class="blueCircle" src="../../assets/media/icons/tag-seguranta.svg" alt="">
                    <div id="tag8content">
                        <h3>Siguranță 100%</h3>
                        <p>Acest cartier este deosebit și prin faptul că va fi administrat de către dezvoltator pe o
                            durată de 25 de ani.<span class="tag-moreDots"> ...<br></span> Practic, cumpărătorul
                            primește un stil de viață bazat pe confort și încredere, mod de a trăi și experiență în
                            cartier.
                        </p>
                        <div class="tag-buttons" id="tagBtn8" (click)="setAllAccordians(15)">
                            <span id="tag-icon8" class="material-symbols-outlined">arrow_circle_down</span>
                            <label class="tag-open"> Citește mai mult </label>
                            <label class="tag-close"> Închide </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tag-slider-controls">
            <span id="tagSlidePrev1" class="material-symbols-outlined">arrow_circle_left</span>
            <span id="tagSlidePrev" class="material-symbols-outlined"
                (click)="buttonSlidePREV()">arrow_circle_left</span>
            <span id="tagSlideNext" class="material-symbols-outlined"
                (click)="buttonSlideNEXT()">arrow_circle_right</span>
            <span id="tagSlideNext1" class="material-symbols-outlined">arrow_circle_right</span>
        </div>
    </section>

    <section class="mob-alege-locuinta">
        <h2>Alege-ți locuința</h2>
        <div class="mob-alege-locuinta-wrapper">
            <div class="mob-choose-bloc">

                <h3>Bloc {{BuildingNameFilter}}</h3>
                <div class="mob-select-blocs">
                    <div class="blue-block"></div>

                    <div id="carouselExampleIndicators1" class="carousel slide" data-ride="0" data-interval="false">
                        <div class="carousel-inner" id="selectBlocs-inner">
                            <div class="carousel-item">
                                <img class="d-block w-100" src="../../assets/media/blocs/bloc-B1.jpg" alt="Bloc B1">
                            </div>

                            <div class="carousel-item active">
                                <img class="d-block w-100" src="../../assets/media/blocs/bloc-C.jpg" alt="Bloc C">
                            </div>

                        </div>

                        <a class="carousel-control-prev" href="#carouselExampleIndicators1" role="button"
                            data-slide="prev" (click)="substractNumber()">
                            <span id="mob-bloc-prev" class="material-symbols-outlined">arrow_circle_left</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleIndicators1" role="button"
                            data-slide="next" (click)="addNumber()">
                            <span id="mob-bloc-next" class="material-symbols-outlined">arrow_circle_right</span>
                        </a>
                    </div>


                    <div class="mob-dropdown">
                        <div class="etaj-dropdown">
                            <div class="blocs-cicle"><span id="etaj-big-arrow"
                                    class="material-symbols-outlined">chevron_right</span>
                            </div>
                            <div class="etaj-names" id="moreetaj">
                                <h6 class="etaj-arrow">Etaj</h6>
                                <ul class="nrEtaj">
                                    <li (click)="makeEtajParter()">0</li>
                                    <li (click)="makeEtaj1()">1</li>
                                    <li (click)="makeEtaj2()">2</li>
                                    <li (click)="makeEtaj3()">3</li>
                                    <li (click)="makeEtaj4()">4</li>
                                </ul>
                            </div>
                        </div>
                        <div class="cam-dropdown">
                            <div class="blocs-cicle"><span id="cam-big-arrow"
                                    class="material-symbols-outlined">chevron_right</span></div>
                            <div class="cam-names" id="moreCam">
                                <h6 class="cam-arrow">Camere</h6>
                                <ul class="nrCam">
                                    <li class="deactive" (click)="makerooms1()">1</li>
                                    <li class="deactive" (click)="makerooms2()">2</li>
                                    <li class="deactive" (click)="makerooms3()">3</li>
                                    <li class="deactive" (click)="makerooms4()">4</li>
                                    <li class="deactive" (click)="makerooms5()">5</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="mob-flats-plans">
                <div class="border-horiz"></div>
                <div class="plans-details">
                    <div class="roomsNo">
                        <h5>{{hollowNrOfRooms}} / {{hollowFloor}}</h5>
                        <p>{{hollowApartmentNumb}}</p>
                    </div>
                    <div class="split-line"></div>
                    <div class="s-utila">
                        <h5>S. Utilă</h5>
                        <p>{{hollowSurface}}</p>
                    </div>
                </div>
                <div class="plans-imgs">
                    <img src="{{hollowPhoto}}" alt="">
                </div>
                <button class="detail-btn" (click)="seeApartment()">DETALII APARTAMENT</button>
                <div class="choose-plans-btns">
                    <p>APARTAMENTUL ANTERIOR</p>
                    <span id="mob-plans-prev1" class="material-symbols-outlined">arrow_circle_left</span>
                    <span id="mob-plans-prev" (click)="slidetoprevappartment()"
                        class="material-symbols-outlined">arrow_circle_left</span>
                    <span id="mob-plans-next" (click)="slidetonextappartment()"
                        class="material-symbols-outlined">arrow_circle_right</span>
                    <span id="mob-plans-next1" class="material-symbols-outlined">arrow_circle_right</span>

                    <p>APARTAMENTUL URMATOR</p>
                </div>
                <button class="deactive" (click)="seemoreapartments()">VEZI APARTAMENTELE SELECTATE</button>
            </div>
        </div>

    </section>

    <section class="alege-locuinta">
        <h2>Alege-ți locuința</h2>
        <div class="gray-block"><span id="gray-arrow" class="material-symbols-outlined">trending_flat</span>
        </div>
        <div class="alege-locuinta-wrapper">
            <div class="choose-bloc">
                <h3>Bloc {{BuildingNameFilter}}</h3>
                <p>Selectează etajul <br> pe&nbsp;imagine</p>

                <div class="select-blocs">
                    <div id="filtercarusel" class="carousel slide" data-ride="carousel " data-interval="false">
                        <div class="carousel-inner" id="selectBlocsInner">
                            <div class="carousel-item active">
                                <img class="d-block w-100" src="../../assets/media/blocs/bloc-B1hover.jpg"
                                    alt="First slide">
                            </div>

                            <div class="carousel-item ">
                                <img class="d-block w-100" src="../../assets/media/blocs/bloc-C.jpg" alt="Second slide">
                            </div>

                        </div>
                        <a class="carousel-control-prev" href="#filtercarusel" role="button" data-slide="prev"
                            (click)="substractNumber()">
                            <span id="bloc-prev" class="material-symbols-outlined">arrow_circle_left</span>
                        </a>
                        <a class="carousel-control-next" href="#filtercarusel" role="button" data-slide="next"
                            (click)=" addNumber()">
                            <span id="bloc-next" class="material-symbols-outlined">arrow_circle_right</span>
                        </a>
                    </div>


                    <ul id="blC-hover-btns">
                        <li class="mansarda" (click)="makeEtaj4()"></li>
                        <li class="etaj3" (click)="makeEtaj3()"></li>
                        <li class="etaj2" (click)="makeEtaj2()"></li>
                        <li class="etaj1" (click)="makeEtaj1()"></li>
                        <li class="parter" (click)="makeEtajParter()"></li>
                    </ul>

                    <ul id="blB1-hover-btns">
                        <li class="mansarda" (click)="makeEtaj4()"></li>
                        <li class="etaj3" (click)="makeEtaj3()"></li>
                        <li class="etaj2" (click)="makeEtaj2()"></li>
                        <li class="etaj1" (click)="makeEtaj1()"></li>
                        <li class="parter" (click)="makeEtajParter()"></li>
                    </ul>


                </div>

                <button class="deactive" (click)="seemoreapartments()">vezi apartamentele selectate</button>
            </div>
            <div class="choose-etajFlat">
                <div class="etaj-flat">
                    <div class="choose-etaj">
                        <h4>Etaj</h4>
                        <ul class="etaj-btns" id="etajBtns">
                            <li (click)="makeEtajParter()">0</li>
                            <li (click)="makeEtaj1()">1</li>
                            <li (click)="makeEtaj2()">2</li>
                            <li (click)="makeEtaj3()">3</li>
                            <li (click)="makeEtaj4()">4</li>

                        </ul>
                    </div>
                    <div class="choose-room">
                        <h4>NR.CAMERE</h4>
                        <ul class="camere-btns">
                            <li class="deactive" (click)="makerooms1()">1</li>
                            <li class="deactive" (click)="makerooms2()">2</li>
                            <li class="deactive" (click)="makerooms3()">3</li>
                            <li class="deactive" (click)="makerooms4()">4</li>
                            <li class="deactive" (click)="makerooms5()">5</li>
                        </ul>
                    </div>
                </div>

                <div class="flats-plans">
                    <div class="plans-imgs">
                        <ul>
                            <img src={{hollowPhoto}} alt="">
                        </ul>

                    </div>
                    <div class="border-horiz"></div>
                    <div class="plans-details">
                        <div class="roomsNo">
                            <h5>{{hollowNrOfRooms}} / {{hollowFloor}}</h5>
                            <p>{{hollowApartmentNumb}}</p>
                        </div>
                        <div class="split-line"></div>
                        <div class="s-utila">
                            <h5>S. Utilă</h5>
                            <p>{{hollowSurface}}</p>
                        </div>
                        <button (click)="seeApartment()">VEZI DETALII</button>
                    </div>
                    <ul class="plan-indicator">
                        <!--  <li *ngFor="dataItem of BuildingList"></li>  -->
                    </ul>
                    <div class="choose-plans-btns">
                        <span id="plans-prev1" class="material-symbols-outlined">arrow_circle_left</span>
                        <span id="plans-prev" (click)="slidetoprevappartment()"
                            class="material-symbols-outlined">arrow_circle_left</span>
                        <span id="plans-next" (click)="slidetonextappartment()"
                            class="material-symbols-outlined">arrow_circle_right</span>
                        <span id="plans-next1" class="material-symbols-outlined">arrow_circle_right</span>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="mob-timeline">
        <div class="mob-blue-block"></div>
        <div id="mob-timeline-image-wrapper">
            <div class="mob-white-block"></div>
            <img src="../../assets/media/the-lake-timeline.png" alt="">

            <!--top infos-->
            <div class="blocB2">
                <h5>Bloc&nbsp;B2</h5>
                <h6>FAZA&nbsp;NR.&nbsp;2.2</h6>
                <p>Mar.&nbsp;2023 <br> Iun.&nbsp;2024</p>
                <div class="pointers-top">
                    <div class="tl-lines-top"></div>
                    <div class="tl-circles-top"></div>
                </div>
            </div>

            <div class="blocD">
                <h5>Bloc&nbsp;D</h5>
                <h6>FAZA&nbsp;NR.&nbsp;3.2</h6>
                <p>Oct.&nbsp;2025<br> Ian.&nbsp;2026</p>
                <div class="pointers-top">
                    <div class="tl-lines-top"></div>
                    <div class="tl-circles-top"></div>
                </div>
            </div>

            <div class="blocE">
                <h5>Bloc&nbsp;E</h5>
                <h6>FAZA&nbsp;NR.&nbsp;2.2</h6>
                <p>Mar.&nbsp;2023 <br> Iun.&nbsp;2024</p>
                <div class="pointers-top">
                    <div class="tl-lines-top"></div>
                    <div class="tl-circles-top"></div>
                </div>
            </div>

            <div class="venue-spa">
                <h5>Gym,&nbsp;Piscină<br>&&nbsp;Spa</h5>
                <h6>FAZA&nbsp;NR.&nbsp;3.1</h6>
                <p>Ian.&nbsp;2024 <br> Dec.&nbsp;2024</p>
                <div class="pointers-top">
                    <div class="tl-lines-top"></div>
                    <div class="tl-circles-top"></div>
                </div>
            </div>

            <div class="vile">
                <h5>Vile</h5>
                <h6>FAZA&nbsp;NR.&nbsp;4</h6>
                <p>Ian.&nbsp;2024 <br> Ian.&nbsp;2025</p>
                <div class="pointers-top">
                    <div class="tl-lines-top"></div>
                    <div class="tl-circles-top"></div>
                </div>
            </div>
            <!--down infos-->
            <div class="blocA">
                <div class="pointers-down">
                    <div class="tl-circles-down"></div>
                    <div class="tl-lines-down"></div>
                </div>
                <h5>Bloc&nbsp;A</h5>
                <h6>FAZA&nbsp;NR.&nbsp;5</h6>
                <p>Ian.&nbsp;2024 <br> Ian.&nbsp;2025</p>
            </div>

            <div class="blocB1">
                <div class="pointers-down">
                    <div class="tl-circles-down"></div>
                    <div class="tl-lines-down"></div>
                </div>
                <h5>Bloc&nbsp;B1</h5>
                <h6>FAZA&nbsp;NR.&nbsp;2.1</h6>
                <p>Mai.&nbsp;2022 <br> Iun.&nbsp;2023</p>
            </div>

            <div class="blocC">
                <div class="pointers-down">
                    <div class="tl-circles-down"></div>
                    <div class="tl-lines-down"></div>
                </div>
                <h5>Bloc&nbsp;C</h5>
                <h6>FAZA&nbsp;NR.&nbsp;1</h6>
                <p>Finalizată</p>
            </div>

            <div class="blocF">
                <div class="pointers-down">
                    <div class="tl-circles-down"></div>
                    <div class="tl-lines-down"></div>
                </div>
                <h5>Bloc&nbsp;F</h5>
                <h6>FAZA&nbsp;NR.&nbsp;4</h6>
                <p>Ian.&nbsp;2024 <br> Dec.&nbsp;2025</p>
            </div>

            <div class="blocG">
                <div class="pointers-down">
                    <div class="tl-circles-down"></div>
                    <div class="tl-lines-down"></div>
                </div>
                <h5>Bloc&nbsp;G</h5>
                <h6>IN&nbsp;ASTEPTARE</h6>
                <p>ApartHotel</p>
            </div>

            <div class="blocH">
                <div class="pointers-down">
                    <div class="tl-circles-down"></div>
                    <div class="tl-lines-down"></div>
                </div>
                <h5>Bloc&nbsp;H</h5>
                <h6>FAZA&nbsp;NR.&nbsp;5</h6>
                <p>Ian.&nbsp;2025 <br> Ian.&nbsp;2026</p>
            </div>

            <div class="mob-restaurant">
                <div class="pointers-down">
                    <div class="tl-circles-down"></div>
                    <div class="tl-lines-down"></div>
                </div>
                <h5>Restaurant</h5>
                <h6>FAZA&nbsp;NR.&nbsp;2.2</h6>
                <p>Ian.&nbsp;2023 <br> Dec.&nbsp;2023</p>
            </div>

            <div class="blocI">
                <div class="pointers-down">
                    <div class="tl-circles-down"></div>
                    <div class="tl-lines-down"></div>
                </div>
                <h5>Bloc&nbsp;I</h5>
                <h6>FAZA&nbsp;NR.&nbsp;5</h6>
                <p>Ian.&nbsp;2025 <br> Ian.&nbsp;2026</p>
            </div>
        </div>

        <div class="mob-timeline-btns">
            <span id="timeline-prev1" class="material-symbols-outlined">arrow_circle_left</span>
            <span id="timeline-prev" (click)="buttonSlidePREV1()"
                class="material-symbols-outlined">arrow_circle_left</span>
            <span id="timeline-next" (click)="buttonSlideNEXT1()"
                class="material-symbols-outlined">arrow_circle_right</span>
            <span id="timeline-next1" class="material-symbols-outlined">arrow_circle_right</span>
        </div>
    </section>

    <section class="timeline">
        <div class="blue-block"></div>
        <div class="timeline-title">
            <div class="timeline-title-wrapper">
                <div class="title-dots"></div>
                <h3>Calendarul fazelor de construcție</h3>
                <div class="title-dots"></div>
            </div>
            <div class="timeline-image-wrapper">
                <div class="white-block"></div>
                <img src="../../assets/media/the-lake-timeline.png" alt="">

                <!--top infos-->
                <div class="blocB2">
                    <h5>Bloc B2</h5>
                    <h6>FAZA NR. 2.2</h6>
                    <p>Mar.&nbsp;2023 <br> Iun.&nbsp;2024</p>
                    <div class="pointers-top">
                        <div class="tl-lines-top"></div>
                        <div class="tl-circles-top"></div>
                    </div>
                </div>

                <div class="blocD">
                    <h5>Bloc D</h5>
                    <h6>FAZA NR. 3.2</h6>
                    <p>Oct.&nbsp;2025<br> Ian.&nbsp;2026</p>
                    <div class="pointers-top">
                        <div class="tl-lines-top"></div>
                        <div class="tl-circles-top"></div>
                    </div>
                </div>

                <div class="blocE">
                    <h5>Bloc E</h5>
                    <h6>FAZA NR. 2.2</h6>
                    <p>Mar.&nbsp;2023 <br> Iun.&nbsp;2024</p>
                    <div class="pointers-top">
                        <div class="tl-lines-top"></div>
                        <div class="tl-circles-top"></div>
                    </div>
                </div>

                <div class="venue-spa">
                    <h5>Gym,&nbsp;Piscină<br>&&nbsp;Spa</h5>
                    <h6>FAZA&nbsp;NR.&nbsp;3.1</h6>
                    <p>Ian.&nbsp;2024 <br> Dec.&nbsp;2024</p>
                    <div class="pointers-top">
                        <div class="tl-lines-top"></div>
                        <div class="tl-circles-top"></div>
                    </div>
                </div>

                <div class="vile">
                    <h5>Vile</h5>
                    <h6>FAZA NR. 4</h6>
                    <p>Ian.&nbsp;2024 <br> Ian.&nbsp;2025</p>
                    <div class="pointers-top">
                        <div class="tl-lines-top"></div>
                        <div class="tl-circles-top"></div>
                    </div>
                </div>
                <!--down infos-->
                <div class="blocA">
                    <div class="pointers-down">
                        <div class="tl-circles-down"></div>
                        <div class="tl-lines-down"></div>
                    </div>
                    <h5>Bloc A</h5>
                    <h6>FAZA NR. 5</h6>
                    <p>Ian.&nbsp;2024 <br> Ian.&nbsp;2025</p>
                </div>

                <div class="blocB1">
                    <div class="pointers-down">
                        <div class="tl-circles-down"></div>
                        <div class="tl-lines-down"></div>
                    </div>
                    <h5>Bloc B1</h5>
                    <h6>FAZA NR. 2.1</h6>
                    <p>Mai.&nbsp;2022 <br> Iun.&nbsp;2023</p>
                </div>

                <div class="blocC">
                    <div class="pointers-down">
                        <div class="tl-circles-down"></div>
                        <div class="tl-lines-down"></div>
                    </div>
                    <h5>Bloc C</h5>
                    <h6>FAZA NR. 1</h6>
                    <p>Finalizată</p>
                </div>

                <div class="blocF">
                    <div class="pointers-down">
                        <div class="tl-circles-down"></div>
                        <div class="tl-lines-down"></div>
                    </div>
                    <h5>Bloc F</h5>
                    <h6>FAZA NR. 4</h6>
                    <p>Ian.&nbsp;2024 <br> Dec.&nbsp;2025</p>
                </div>

                <div class="blocG">
                    <div class="pointers-down">
                        <div class="tl-circles-down"></div>
                        <div class="tl-lines-down"></div>
                    </div>
                    <h5>Bloc G</h5>
                    <h6>IN ASTEPTARE</h6>
                    <p>ApartHotel</p>
                </div>

                <div class="blocH">
                    <div class="pointers-down">
                        <div class="tl-circles-down"></div>
                        <div class="tl-lines-down"></div>
                    </div>
                    <h5>Bloc H</h5>
                    <h6>FAZA NR. 5</h6>
                    <p>Ian.&nbsp;2025 <br> Ian.&nbsp;2026</p>
                </div>

                <div class="restaurant">
                    <div class="pointers-down">
                        <div class="tl-circles-down"></div>
                        <div class="tl-lines-down"></div>
                    </div>
                    <h5>Restaurant</h5>
                    <h6>FAZA&nbsp;NR.&nbsp;2.2</h6>
                    <p>Ian.&nbsp;2023 <br> Dec.&nbsp;2023</p>
                </div>

                <div class="blocI">
                    <div class="pointers-down">
                        <div class="tl-circles-down"></div>
                        <div class="tl-lines-down"></div>
                    </div>
                    <h5>Bloc I</h5>
                    <h6>FAZA NR. 5</h6>
                    <p>Ian.&nbsp;2025 <br> Ian.&nbsp;2026</p>
                </div>

            </div>
        </div>
    </section>

    <section class="investitia-wrapper">
        <div class="inv-wrapper-wrapper">
            <div class="investitia">
                <h2>Care este investiția ideală pentru&nbsp;tine</h2>
                <div class="investitia-blueBar"></div>
                <p>Investițiile imobiliare inteligente au fost, sunt și vor fi unul dintre cele mai bune moduri de
                    transport al banilor în timp</p>
            </div>
            <div class="calculator">

                <div class="bigdiv">
                    <h3>Calculator investiție</h3>
                    <p class="suma">Sumă investită</p>
                    <input class="calcone" id="first" min="7000" placeholder="suma în €" type="number">
                    <p class="termen">Termen <br></p>
                    <div class="labelandimput">
                        <div class="labelandimput2">
                            <input checked="checked" type="radio" id="age2" name="age" value="15" required>
                            <label class="tag" for="age2">18&nbsp;luni</label>
                        </div>
                        <div class="labelandimput2">
                            <input type="radio" id="age3" name="age" value="35">
                            <label class="tag" for="age3">36&nbsp;luni</label>
                        </div>
                        <div class="labelandimput2">
                            <input type="radio" id="age4" name="age" value="55">
                            <label class="tag" for="age4">54&nbsp;luni</label>
                        </div>
                    </div>
                    <button onclick="add()" class="calc-btn">CALCULEAZĂ-ȚI INVESTITIA</button>
                    <!--<div onclick="add()" class="changeBtn">
                      <a class="calc-btn">CALCULEAZĂ-ȚI INVESTITIA</a>
                      </div>-->
                </div>
                <div class="rectangle">
                    <p class="procentul">Profit la termen&nbsp;%
                        <input class="firstresult" id="answer3" disabled="disabled" placeholder="_ %">
                    </p>

                    <p class="dobanda">Profit la termen&nbsp;€
                        <input class="firstresult" id="answer2" disabled="disabled" placeholder="_ €">
                    </p>

                    <p class="return">Sumă returnată la termen&nbsp;€
                        <input class="firstresult" id="answer" disabled="disabled" placeholder="_ €">
                    </p>
                </div>

            </div>
        </div>
    </section>

    <section class="lifestyle">
        <h2>Lifestyle</h2>
        <div class="lifestyleWrapper">
            <div class="lifesText">
                <h3>Bucuria vieții surprinsă și integrată&nbsp;arhitectural.</h3>
            </div>
            <img class="lifesImg" src="../../assets/media/pattern/Artwork29.svg" alt="">
            <p class="lifesTxt">Spațiile rezidențiale se întrepătrund în mod armonios, într&nbsp;-&nbsp;o relație de
                “unu la unu”, cu cele comune - străzi, piețe și parcuri - ambele tipuri având un scop comun: să îți
                încânte privirea, în reflexia minunată a razelor soarelui pe luciul&nbsp;lacului. <br><br> Criteriile de
                proiectare au fost aliniate cu deosebită grijă, pentru a-ți oferi un habitat perfect integrat în
                specificul&nbsp;locului.</p>
            <button class="lifesBtn" (click)="lifestyle()">DESCOPERĂ EXPERIENȚA</button>
        </div>
        <div class="vertLine"></div>
        <div class="lifestyleImg"></div>
    </section>


    <div class="demo">
        <div style="margin: 20px; text-align: -webkit-center;">
            <carousel [images]="images" [width]="900" [height]="600" [loop]="true" [cellsToShow]="1" [cellWidth]="1000"
                [overflowCellsLimit]="1" (next)='myNextFun()'>
            </carousel>
        </div>
    </div>

    <div class="demo-mob">
        <div style="margin: 20px; text-align: -webkit-center;">
            <carousel [images]="images" [width]="330" [height]="200" [loop]="true" [cellsToShow]="1" [cellWidth]="1000"
                [overflowCellsLimit]="1" (next)='myNextFun1()'>
            </carousel>
        </div>
    </div>

    <section class="locatia">
        <h2>Locație</h2>
        <div class="locatiaWrapper">
            <div class="locatiaText">
                <h3>Descoperă potențialul de locație&nbsp;Premium</h3>
            </div>
            <img class="locImg" src="../../assets/media/pattern/Artwork30.svg" alt="">
            <p class="locatiaTxt">În imobiliare, există 3 factori esențiali: locația, locația și… locația. Ansamblul
                este situat în zona de vest a Sibiului, beneficiind de acces direct spre autostrada A1 și de conexiuni
                rutiere rapide cu aeroportul, dar și cu centrul economic al orașului. <br><br> Mai mult decât atât,
                proiectul este construit pe malul singurului lac comercial din oraș, o zonă reamenajată recent de
                Primăria Sibiu pentru activități de agrement.</p>
            <button class="locatiaBtn" (click)="locatie()">DESCOPERĂ LOCAȚIA</button>
        </div>
        <div class="vertLine2"></div>
        <div class="locatiaImgs"></div>
    </section>

    <div class="locatia-accordion">
        <div id="panel1" (click)="locatiaAccordion(0)">
            <button id="accord1" class="loc-accordion">Educație<span id="loc-icon1"
                    class="material-symbols-outlined">arrow_downward</span></button>
            <p>Grădinița nr.19, Școala nr.12, Școala nr. 13. Școala nr. 18, Școala nr. 23</p>
        </div>

        <div id="panel2" (click)="locatiaAccordion(1)">
            <button id="accord2" class="loc-accordion">cumpărături<span id="loc-icon2"
                    class="material-symbols-outlined">arrow_downward</span></button>
            <p>Prima Shopping Center, Promenada Mall, Piața agroalimentară Cibin</p>
        </div>

        <div id="panel3" (click)="locatiaAccordion(2)">
            <button id="accord3" class="loc-accordion">Sănătate<span id="loc-icon3"
                    class="material-symbols-outlined">arrow_downward</span></button>
            <p>În imediata apropiere a cartierului se va construi noul spital regional din Sibiu.</p>
        </div>

        <div id="panel4" (click)="locatiaAccordion(3)">
            <button id="accord4" class="loc-accordion">Agrement<span id="loc-icon4"
                    class="material-symbols-outlined">arrow_downward</span></button>
            <p>În funcție de starea vremii sau de starea ta, aici faci sport indoor sau outdoor.</p>
        </div>
    </div>

    <section class="testemonials">
        <h2>Testimoniale</h2>

        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="false">
            <div class="carousel-inner" id="testm-inner">
                <div class="carousel-item active">
                    <iframe src="https://www.youtube.com/embed/9kgNFTFkvRo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="carousel-item">
                    <iframe src="https://www.youtube.com/embed/m_XtPUMQ2dA" title="YouTube video player" frameborder="0" allow="encrypted-media; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="carousel-item">
                    <iframe src="https://www.youtube.com/embed/RwkO60TJF9g" title="YouTube video player" frameborder="0" allow="encrypted-media; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="carousel-item">
                    <iframe src="https://youtube.com/embed/Kqv1PDt_4Gg?feature=share" title="YouTube video player" frameborder="0" allow="encrypted-media; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div class="slider-controls-Testm">
                <span id="testm-slide-prev" class="material-symbols-outlined left-btn-testm"
                    href="#carouselExampleControls" role="button" data-slide="prev">arrow_circle_left</span>
                <span id="testm-slide-next" class="material-symbols-outlined right-btn-testm"
                    href="#carouselExampleControls" role="button" data-slide="next">arrow_circle_right</span>
            </div>
        </div>

        <div class="bg-img-testm"></div>
    </section>

    <footer>
        <div class="footer-wrapper">
            <div class="footer-logo">
                <p>Proiect dezvoltat de:</p>
                <img src="../../assets/media/logos/sigla_negative-02.png" alt="Novarion Logo"
                    onclick="NewTabNovarion()">
            </div>
            <div class="footer1">
                <h5>Birou vânzări:</h5>
                <p class="footer1-bold">The Lake Home</p>
                <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target=”_blank”>
                    <p>Strada Tractorului, nr. 12- 28,<br>Sibiu, România</p>
                </a>
                <div>
                    <span>T.</span><a href="tel:+40721229999">+40 721 229 999</a> <br><br>
                    <span>T.</span><a href="mailto:sales@thelake.ro">sales@thelake.ro</a>
                </div>
                <img src="../../assets/media/pattern/brand_icon.svg" alt="">
            </div>
            <div class="footer2">
                <h5>Alege-ți rezidența</h5>
                <div class="footer2-rezidentie">
                    <div class="rezidentie1">
                        <a (click)="footerlinks1()">APARTAMENT STUDIO DUBLU</a> <br>
                        <a (click)="footerlinks2()">APARTAMENT CU 2 CAMERE</a><br>
                        <a (click)="footerlinks3()">APARTAMENT CU 3 CAMERE</a><br>
                        <a (click)="footerlinks4()">APARTAMENT CU 5 CAMERE</a><br>
                    </div>
                    <!--
                      <div class="rezidentie2">
                          <a href="">VILA TIP A</a><br>
                          <a href="">VILA TIP B</a><br>
                          <a href="">VILA TIP C</a><br>
                      </div>-->
                </div>
                <div class="footer2-pages">
                    <div class="pages1">
                        <a (click)="seehome()">HOME</a> <br>
                        <a (click)="lifestyle()">LIFESTYLE</a><br>
                        <a (click)="locatie()">LOCAȚIE</a><br>
                    </div>
                    <div class="pages2">
                        <a (click)="galerie()">GALERIE</a><br>
                        <a (click)="seemedia()">MEDIA</a><br>
                        <a (click)="seedezvoltator()">DEZVOLTATOR</a><br>
                    </div>
                </div>
                <div class="footer2-socialMedia">
                    <img src="../../assets/media/icons/facebook_logo_icon.svg" alt="Facebook link" onclick="NewTabFb()">
                    <img src="../../assets/media/icons/linkedin_logo_icon.svg" alt="LinkedIn link" onclick="NewTabLi()">
                    <img src="../../assets/media/icons/instagram_logo_icon.svg" alt="Instagram link"
                        onclick="NewTabIg()">
                    <img src="../../assets/media/icons/youtube_icon.svg" alt="Youtube link" onclick="NewTabYt()">
                </div>
                <div class="footer2-end">
                    <a (click)="seetermeni()">Politica de confidențialitate | Cookies</a>
                    <p>Brand & Web Design : <a href="https://www.mugurelfrincu.ro/" target="_blank"> Mugurel Frîncu</a>
                    </p>
                </div>
            </div>
            <div class="footer3">
                <h4>Te contactăm noi</h4>
                <p>Completează formularul de mai jos pentru a primi în cel mai scurt timp un răspuns din partea echipei
                    de vânzări</p>
                <input type="text" placeholder="Nume" [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                <input type="email" placeholder="Email" [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                    required>
                <input type="tel" placeholder="Telefon" [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone"
                    required>
                <textarea id="mesaj" placeholder="Scrie-ne un mesaj" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="Message"></textarea>
                <div class="privacylink3"><input (click)="checked3()" id="checkbox3" type="checkbox" required><label
                        class="form-control3">Sunt de acord cu <a id="ppLink3" (click)="seetermeni()"> Consimțământul de
                            prelucrare a datelor.</a></label></div>
                <h6 *ngIf="acestCamp2">Acest câmp este obligatoriu</h6>
                <button class="deactive" type="button" (click)="sendFormOne3()">TRIMITE MESAJUL</button>
            </div>
        </div>
    </footer>
</body>

<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<router-outlet></router-outlet>

<script src="src/assets/scripts.js"></script>
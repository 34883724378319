import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SharedService {
//PUT THE LINK HERE REMINDER //
readonly APIUrl = "https://thelake.ro/";
readonly PhotoUrl = "https://thelake.ro/media/";

BlogList = [
  { blogtitlu: 'First Blog', blogsursa: 'Source 1', blogcategorie: 'Category 1', blogcuprins: 'Content of first blog.', blogphoto: 'image1.jpg' },
  { blogtitlu: 'Second Blog', blogsursa: 'Source 2', blogcategorie: 'Category 2', blogcuprins: 'Content of second blog.', blogphoto: 'image2.jpg' }
];

selectedBlog:any

editBlogSelect:any

selectedApartment:any
selectedApartmenDesktop:any

selectedfloor!: string;
pretlace!: string;
selectedrooms !: string;
selectedbuilding !: string;

selectEnRo:boolean=false;

activateBrosura:boolean=false;

RoEnselect:boolean=true;
constructor(private http:HttpClient) { }

serviceRoDetalii:boolean=false;
serviceEnDetalii:boolean=false;

apartamentdinDesktop:boolean=false;
apartamentdinMobile:boolean=false;


//invest redirect
invest:boolean=false;
//anunt castiga qr
qrchecklistdeactive:boolean=false;
//checklist
checklist1!: string;
checklist2!: string;

date!: string;
idapto!: string;
anunt!: string;
adresa!: string;
pret!: string;
pretnegoc!: string;
agent!: string;
telefon!: string;
email!: string;
mpconsttotal!: string;
mputiletotal!: string;
etaj!: string;
dinEtaj!: string;
nrcamere!: number;
mobiliat!: string;
cameraMp!: string;
balcon_terasa!: string;
cameraNote!: string;
bucatariaMp!: string;
bucatariaMobi!: string;
bucatariaElect!: string;
inchisadeschisa!: string;
bucatariaNote!: string;

baie1Note!: string;
baie2Mp!: string;

dormitor1Mp!: string;
dormitor2Mp!: string;
dormitor2Amenaj!: string;
dormitor2Renov!: string;
dormitor2Note!: string;

balconMp!: string;
balconDesc!: string;
balconNote!: string;
curteMp!: string;
curteGeam!: string;
curteNote!: string;
anconstr!: string;
nrScari!: string;
nrApto!: string;
boxa!: string;
lift!: string;
nrparcare!: number;

parcPoz!: string;
parcLoc!: string;
parcNote!: string;
conditNou!: string;
conditAnv!: string;
conditNote!: string;
detaliiChelt!: string;
docs1!: string;
docs1nu!: string;
docs2!: string;
care!: string;
docs3!: string;
docs4!: string;
docs5!: string;
docs6!: string;
docs7!: string;

quest1!: string;
quest2!: string;
quest3!: string;

  advanced1!: number;
  advanced2!: number;
  advanced3!: number;
  advanced4!: number;
  advanced5!: number;
  advanced6!: number;
  advanced7!: number;
  advanced8!: number;
  advanced9!: number;
  advanced10!: number;
  advanced11!: number;
  advanced12!: number;
  advanced13!: number;
  advanced14!: number;
  advanced15!: number;
  advanced16!: number;
  advanced17!: number;
  advanced18!: number;
  advanced19!: number;
  advanced20!: number;
  advanced21!: number;
  advanced22!: number;
  advanced23!: number;
  advanced24!: number;
  advanced25!: number;
  advanced26!: number;
  advanced27!: number;
  advanced28!: number;
  advanced29!: number;
  advanced30!: number;

iBathrooms!: number;
detaliibaie: string[] = ['', ''];
numBaie!: number;
baieMp: string[] = ['', ''];
amenajare: string[] = ['', ''];
isNoteActiveBaie: boolean[] = Array(10).fill(false);
baie2Note: string[] = ['', ''];


//dormitor
numroom: number = 2;
roomMp: string[] = ['', ''];
dormitor1Amenaj: string[] = ['', ''];
dormitor1Renov: string[] = ['', ''];
detaliiroom: string[] = ['', ''];
dormitor1Note: string[] = ['', ''];
numarTombola!: string;


alertaIP:boolean=false;


//no date no preview
previewChecklistData:boolean=false;


getBuildingList():Observable<any[]>{
  return this.http.get<any[]>(this.APIUrl + '/building/');
}

addBuilding(val:any){
  return this.http.post(this.APIUrl + 'building/',val);
}

updateBuilding(val:any){
  return this.http.put(this.APIUrl + '/building/',val);
}

deleteBuilding(val:any){
  return this.http.delete(this.APIUrl + '/building/'+val);
}

UploadPhoto(val:any){
  return this.http.post(this.APIUrl+'/SaveFile',val);
}
UploadPhotoB(val:any){
  return this.http.post(this.APIUrl+'/SaveFileB',val);
}
UploadPhoto1(val:any){
  return this.http.post(this.APIUrl+'/SaveFile1',val);
}

getformList():Observable<any[]>{
  return this.http.get<any[]>(this.APIUrl + '/form');
}

addform(val:any){
  return this.http.post(this.APIUrl + '/form',val);
}

updateform(val:any){
  return this.http.put(this.APIUrl + 'form',val);
}

deleteform(val:any){
  return this.http.delete(this.APIUrl + '/form/'+val);
}

//adauga formular thelakepark
sendparkform(val:any){
  return this.http.post(this.APIUrl + '/ParkAPI',val);
}


//blog api
getBlogList():Observable<any[]>{
  return this.http.get<any[]>(this.APIUrl + 'alog/');
}

addBlog(val:any){
  return this.http.post(this.APIUrl + 'alog/',val);
}

updateBlog(val:any){
  return this.http.put(this.APIUrl + 'alog/',val);
}

deleteBlog(val:any){
  return this.http.delete(this.APIUrl + 'alog/'+val);
}



}

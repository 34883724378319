import {HttpClientModule} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import{SharedService} from './shared.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {IvyCarouselModule} from 'angular-responsive-carousel';

import {DatePipe} from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { AddAdminComponent } from './admin/add-admin/add-admin.component';
import { FormsComponent } from './forms/forms.component';
import { AddformsComponent } from './forms/addforms/addforms.component';
import { EditAdminComponent } from './forms/edit-admin/edit-admin.component';
import { LocuinteComponent } from './locuinte/locuinte.component';
import { ApartamenteComponent } from './apartamente/apartamente.component';
import { ContactComponent } from './contact/contact.component';
import { DezvoltatorComponent } from './dezvoltator/dezvoltator.component';
import { LifestyleComponent } from './lifestyle/lifestyle.component';
import { LocatieComponent } from './locatie/locatie.component';
import { MediapageComponent } from './mediapage/mediapage.component';
import { TermsComponent } from './terms/terms.component';
import { QrredirectComponent } from './qrredirect/qrredirect.component';
import { ApreviewComponent } from './locuinte/apreview/apreview.component';
import { TestHomeComponent } from './test-home/test-home.component';
import { EnglishHComponent } from './home/english-h/english-h.component';
import { EnglishLComponent } from './lifestyle/english-l/english-l.component';
import { EnglishLoComponent } from './locatie/english-lo/english-lo.component';
import { EnglishMComponent } from './mediapage/english-m/english-m.component';
import { EnglishDComponent } from './dezvoltator/english-d/english-d.component';
import { EnglishCComponent } from './contact/english-c/english-c.component';
import { EnglishRezComponent } from './locuinte/english-rez/english-rez.component';
import { EnglishAComponent } from './apartamente/english-a/english-a.component';
import { EnglishTComponent } from './terms/english-t/english-t.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { EnglishLpComponent } from './landing-page/english-lp/english-lp.component';
import { DetailModalComponent } from './locuinte/detail-modal/detail-modal.component';
import { DetaliModalHomeComponent } from './home/detali-modal-home/detali-modal-home.component';
import { LpChecklistComponent } from './lp-checklist/lp-checklist.component';
import { ChecklistPg1Component } from './lp-checklist/checklist-pg1/checklist-pg1.component';
import { ChecklistPg2Component } from './lp-checklist/checklist-pg2/checklist-pg2.component';
import { ChecklistFinalComponent } from './lp-checklist/checklist-final/checklist-final.component';
import { LpChecklistMainComponent } from './lp-checklist-main/lp-checklist-main.component';
import { BrosuraComponent } from './brosura/brosura.component';
import { OpenComponent } from './brosura/open/open.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { BlogComponent } from './blog/blog.component';
import { BlogArticleComponent } from './blog/blog-article/blog-article.component';
import { ThanksComponent } from './lp-checklist-main/thanks/thanks.component';
import { RuffleComponent } from './forms/ruffle/ruffle.component';
import { RulesComponent } from './rules/rules.component';
import { Ruffle2Component } from './forms/ruffle2/ruffle2.component';
import { AddBComponent } from './forms/add-b/add-b.component';
import { AddComponent } from './forms/add-b/add/add.component';
import { AirbnbComponent } from './airbnb/airbnb.component';
import { OfertaComponent } from './oferta/oferta.component';
import { InvestComponent } from './invest/invest.component';
import { EmailComponent } from './email/email.component';
import { EmailEventComponent } from './email-event/email-event.component';
import { SupportFormComponent } from './support-form/support-form.component';
import { BusinessThanksComponent } from './support-form/business-thanks/business-thanks.component';
import { VizionareComponent } from './modals/vizionare/vizionare.component';
import { ModalbrosuraComponent } from './modals/modal-brosura/modal-brosura.component';
import { GalleryComponent } from './gallery/gallery.component';
import { LightboxComponent } from './lightbox/lightbox.component';
import { GalleryEnComponent } from './gallery/gallery-en/gallery-en.component';
import { FloorPlanComponent } from './floor-plan/floor-plan.component';
import { EmailEnComponent } from './email-en/email-en.component';
import { MapComponent } from './map/map.component';
import { DetaliModalComponent } from './floor-plan/detali-modal/detali-modal.component';
import { RentComponent } from './rent/rent.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AdminComponent,
    AddAdminComponent,
    FormsComponent,
    AddformsComponent,
    EditAdminComponent,
    LocuinteComponent,
    ApartamenteComponent,
    ContactComponent,
    DezvoltatorComponent,
    LifestyleComponent,
    LocatieComponent,
    MediapageComponent,
    TermsComponent,
    QrredirectComponent,
    ApreviewComponent,
    TestHomeComponent,
    EnglishHComponent,
    EnglishLComponent,
    EnglishLoComponent,
    EnglishMComponent,
    EnglishDComponent,
    EnglishCComponent,
    EnglishRezComponent,
    EnglishAComponent,
    EnglishTComponent,
    LandingPageComponent,
    EnglishLpComponent,
    DetailModalComponent,
    DetaliModalHomeComponent,
    LpChecklistComponent,
    ChecklistPg1Component,
    ChecklistPg2Component,
    ChecklistFinalComponent,
    LpChecklistMainComponent,
    BrosuraComponent,
    OpenComponent,
    TestimonialsComponent,
    BlogComponent,
    BlogArticleComponent,
    ThanksComponent,
    RuffleComponent,
    RulesComponent,
    Ruffle2Component,
    AddBComponent,
    AddComponent,
    AirbnbComponent,
    OfertaComponent,
    InvestComponent,
    EmailComponent,
    EmailEventComponent,
    SupportFormComponent,
    BusinessThanksComponent,
    VizionareComponent,
    ModalbrosuraComponent,
    GalleryComponent,
    LightboxComponent,
    GalleryEnComponent,
    FloorPlanComponent,
    EmailEnComponent,
    MapComponent,
    DetaliModalComponent,
    RentComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    IvyCarouselModule,
  ],
  providers: [SharedService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
